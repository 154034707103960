/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://f3c734ukbbhkzgbqqfik5iaqaa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-g2a54vkddvg7bko2bciaum2cp4",
    "aws_cognito_identity_pool_id": "us-west-2:c60cbeba-0a9f-4613-b429-c4de87151f6c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_2mZzJCEjh",
    "aws_user_pools_web_client_id": "29utttrbmn5vipbc8c9q8qp6nq",
    "oauth": {}
};


export default awsmobile;
